import React from "react"

import SEO from "../components/SEO"
import HeroSinglePage from "../components/HeroSinglePage"
import safeGetImage from "../utils/safeGetImage"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <HeroSinglePage
        className={"fourohfour__hero"}
        title={"Page not found."}
        text={"The page you are looking for does not exist."}
        backgroundImage={`url(${safeGetImage("office-setup.svg")})`}
      />
  </>
)

export default NotFoundPage
